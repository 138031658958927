
import React from 'react';

function Community() {
  return (
    <div className="content">
      <h2>Community und Veranstaltungen</h2>
      <p>
        Hier findest du Informationen über lokale Blues-Harp-Events und Workshops:
      </p>
      <ul>
        <li>Blues Jam Sessions in deiner Stadt.</li>
        <li>Workshops mit erfahrenen Musikern.</li>
        <li>Jährliche Blues-Festivals.</li>
      </ul>
    </div>
  );
}

export default Community;
