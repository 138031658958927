// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Resources from './pages/Resources';
import Techniques from './pages/Techniques';
import Songs from './pages/Songs';
import Maintenance from './pages/Maintenance';
import Community from './pages/Community';
import Contact from './pages/Contact'; // Importiere den Contact-Component
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/techniques" element={<Techniques />} />
          <Route path="/songs" element={<Songs />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="/community" element={<Community />} />
		  <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
