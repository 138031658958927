import React from 'react';
import { Helmet } from 'react-helmet';

function About() {
  return (
    <div className="content">
	<Helmet>
		<title>Über Uns - Blues-Harp-Tutorial</title>
		<meta name="description" content="Erfahre mehr über die Mission und Vision hinter blues-harp-tutorial.de." />
	  </Helmet>
      <h2>Über uns</h2>
      <p>
        Text ala Lorem Ipsum
      </p>
    </div>
  );
}

export default About;
