// src/components/Footer.js

import React from 'react';
import '../App.css';

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} Blues-Harp Webseite. Alle Rechte vorbehalten.</p>
      <p>
        Folge uns auf: 
        <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer"> TikTok</a> |&nbsp; 
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"> YouTube</a>
      </p>
    </footer>
  );
}

export default Footer;
