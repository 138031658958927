import React from 'react';

function Techniques() {
  return (
    <div className="content">
      <h2>Techniken und Spielstile</h2>
      <p>
        Hier sind einige grundlegende Techniken, die du beim Spielen der Blues-Harp verwenden kannst:
      </p>
      <ul>
        <li>Bending: Wie man die Tonhöhe verändert.</li>
        <li>Tongue Blocking: Eine Technik für besseren Klang.</li>
        <li>Overblowing: Eine fortgeschrittene Technik für mehr Töne.</li>
      </ul>
    </div>
  );
}

export default Techniques;
