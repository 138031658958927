// src/components/Header.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Optional für Hamburger-Icon
import '../App.css'; // Stile hier importieren

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <h1>Willkommen zur Blues-Harp Webseite</h1>
      <nav>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">Über Uns</Link></li>
          <li><Link to="/contact">Kontakt</Link></li>
		  <li><Link to="/songs">Songs</Link></li>
		  <li><Link to="/community">Community</Link></li>
          {/* Füge hier weitere Links hinzu */}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
