import React from 'react';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className="content">
	<Helmet>
	        <title>Willkommen bei Blues-Harp-Tutorial</title>
	        <meta name="description" content="Lerne, wie man die Blues-Harp spielt. Tutorials, Tipps und Tricks für Anfänger und Fortgeschrittene." />
	      </Helmet>	
      <h2>Willkommen bei blues-harp-tutorial.de</h2>
      <p>
        Hallo und herzlich willkommen! Es ist großartig, dass du dein Interesse an der Blues Harp/ Mundharmonika mit mir teilst. Auf dieser Seite findest du alles, was du brauchst, um dieses faszinierende Instrument zu erlernen und zu meistern. Mit über 16 Jahren Erfahrung im Blues Harp spielen weiß ich, worauf es ankommt, um schnell Fortschritte zu machen und die Freude am Musizieren zu entdecken und möchte dies natürlich sehr gern mit dir teilen. Für den Start und das folgende Tutorial reicht eine herkömmliche Blues Harp in C vollkommen aus. Was ich dir als ersten ganz wichten Punkt vorab mitgeben möchte: Wenn du das Spielen der Blues Harp erlernen möchtest, habe Geduld und verzweifle nicht, wenn etwas nicht direkt so klingt wie du es dir wünscht! <b>Übung macht den Meister!</b>
      </p>
      
      <h3>Warum die Blues Harp?</h3>
      <p>
        Die Blues Harp, auch als diatonische Mundharmonika bekannt, ist ein einzigartiges Instrument, das mit seinen 10 Kanzellen eine unglaubliche Vielfalt an musikalischen Möglichkeiten bietet. Von tiefen, gefühlvollen Blues-Klängen bis hin zu schnellen, rhythmischen Melodien – die Blues Harp ist dein Schlüssel zu einem unverwechselbaren Sound. Hier bei blues-harp-tutorial.de lernst du Schritt für Schritt, wie du dieses Instrument beherrschst.
      </p>
      
      <h3>Die 10 Kanzellen der Blues Harp verstehen</h3>
      <p>
        Der erste wichtige Schritt beim Blues Harp lernen ist das Verständnis der 10 Kanzellen deiner diatonischen Mundharmonika. Jede Kanzelle kann sowohl durch Blasen als auch durch Ziehen gespielt werden, wodurch unterschiedliche Töne erzeugt werden. Um dies zu vereinfachen, verwenden wir folgende Notation:
      </p>
      <ul>
        <li><strong>Blasen (Blow):</strong> 4+ steht für das Blasen in die 4. Kanzelle.</li>
        <li><strong>Ziehen (Draw):</strong> 4- steht für das Ziehen durch die 4. Kanzelle.</li>
      </ul>
      <p>
        Diese einfache Methode hilft dir, die Struktur der Blues Harp schnell zu verstehen und erste Töne zu erzeugen. Hier ist eine Übersicht der C-Dur-Tonleiter, die du auf einer Standard-C-Dur-Blues Harp spielen kannst:
      </p>
      
      <pre><code>
        4+ 4- 5+ 5- 6+ 6- 7- 7+<br />
        C  D  E  F  G  A  B  C<br />
      </code></pre>
      
      <p>
        Mit dieser Tonleiter kannst du bereits eine Vielzahl einfacher Melodien spielen und deine ersten Blues-Harp-Songs begleiten.
      </p>
      
      <h3>Dein erstes Lied auf der Blues Harp</h3>
      <p>
        Nichts motiviert mehr, als das erste Lied auf der Blues Harp zu spielen. Deshalb findest du hier auf blues-harp-tutorial.de eine einfache Anleitung, um sofort loszulegen. Ein beliebter Klassiker ist die „Ode an die Freude“ von Beethoven, den du mit den folgenden Tabs spielen kannst:
      </p>
      
      <pre><code>
        5+ 5+ 5- 6+ 6+ 5- 5+ 4-<br />
        4+ 4+ 4- 5+ 5+ 4- 4-<br />
        5+ 5+ 5- 6+ 6+ 5- 5+ 4-<br />
        4+ 4+ 4- 5+ 4- 4+ 4+<br />
      </code></pre>
      
      <p>
        Nutze dafür gern eine C-Harp - ich persönlich spiele dieses Lied aber eher auf der D ;) Übe diese Tabs in der angegebenen Reihenfolge, bis du die Melodie flüssig spielen kannst. Das Gefühl, dein erstes Lied selbstständig zu spielen, wird dich motivieren, weiterzumachen.
      </p>
      
      <p>
        Wenn du bereit bist, tiefer in die Welt der Blues Harp einzutauchen, findest du in meinen <a href="lernen.html">Lernressourcen</a> detaillierte Anleitungen, Übungen und Tutorials, die dir helfen, dein Spiel weiter zu verbessern. Viel Spaß beim Üben, und lass dich nicht entmutigen – die ersten Schritte sind die schwersten.
      </p>

      <section>
        <h2>Unsere beliebtesten Kategorien</h2>
		<div className="category-list">
		  <div className="category">
		    <h3>Kategorie 1</h3>
		    <p>Beschreibung der Kategorie 1</p>
		    <a href="/kategorie1">Mehr erfahren</a>
		  </div>
		  <div className="category">
		    <h3>Kategorie 2</h3>
		    <p>Beschreibung der Kategorie 2</p>
		    <a href="/kategorie2">Mehr erfahren</a>
		  </div>
		  <div className="category">
		    <h3>Kategorie 3</h3>
		    <p>Beschreibung der Kategorie 3</p>
		    <a href="/kategorie3">Mehr erfahren</a>
		  </div>
		  {/* Weitere Kategorien */}
		</div>

      </section>
    </div>
  );
}

export default Home;
