import React from 'react';

function Songs() {
  return (
    <div className="content">
      <h2>Die besten Blues-Harp-Songs</h2>
      <p>
        Hier sind einige der bekanntesten Songs, die die Blues-Harp hervorheben:
      </p>
      <ul>
        <li>"Sweet Home Chicago" - Robert Johnson</li>
        <li>"Pride and Joy" - Stevie Ray Vaughan</li>
        <li>"Love Me Do" - The Beatles</li>
      </ul>
    </div>
  );
}

export default Songs;
