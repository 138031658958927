import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Contact.css'; // Importiere eine CSS-Datei für Styling

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

	fetch('http://localhost/blues-harp-lernen/src/php/submit_contact.php', {
	  method: 'POST',
	  headers: {
	    'Content-Type': 'application/json',
	  },
	  body: JSON.stringify({
	    name: formData.name,
	    email: formData.email,
	    message: formData.message,
	  }),
	})
	.then((response) => response.json())
	.then((data) => {
	  console.log('Server Response:', data);
	})
	.catch((error) => {
	  console.error('Fehler:', error);
	});

  };

  return (
    <div className="contact-container">
      <Helmet>
        <title>Kontakt - Blues-Harp-Tutorial</title>
        <meta
          name="description"
          content="Kontaktiere uns für Fragen oder Anfragen zu unseren Blues-Harp-Tutorials."
        />
      </Helmet>
      <h2>Kontakt</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Nachricht</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            className="form-control"
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Absenden</button>
      </form>
    </div>
  );
}

export default Contact;
