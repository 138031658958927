import React from 'react';

function Maintenance() {
  return (
    <div className="content">
      <h2>Pflege und Wartung der Blues-Harp</h2>
      <p>
        Um deine Blues-Harp in gutem Zustand zu halten, solltest du folgendes beachten:
      </p>
      <ul>
        <li>Reinigung: Verwende ein trockenes Tuch.</li>
        <li>Aufbewahrung: Halte sie in einem Etui, um sie vor Schmutz zu schützen.</li>
        <li>Stimmzungen: Lerne, wie man sie einstellt.</li>
      </ul>
    </div>
  );
}

export default Maintenance;
