import React from 'react';

function Resources() {
  return (
    <div className="content">
      <h2>Lernressourcen für Anfänger</h2>
      <p>
        Hier sind einige nützliche Ressourcen, um das Spielen der Blues-Harp zu erlernen:
      </p>
      <ul>
        <li>Youtube-Tutorials: Suche nach "Blues Harp Lessons".</li>
        <li>Empfohlene Bücher: "Blues Harp for Dummies".</li>
        <li>Online-Kurse: Websites wie Udemy oder Skillshare.</li>
      </ul>
    </div>
  );
}

export default Resources;
